import {FC, useEffect, useRef, useState} from 'react';

import {Col, Row, Typography} from 'antd';
import classNames from 'classnames';
import {observer} from 'mobx-react';
import useStores from 'Stores';

import MenuChat from '../MenuChat';
import s from './styles.module.scss';

const MenuChats: FC = () => {
    const {chats} = useStores().historyStore;
    const chatsRef = useRef<HTMLDivElement>(null);
    const [scrollState, setScrollState] = useState({
        isTop: false,
        isBottom: false,
    });

    const handleScroll = () => {
        if (chatsRef.current) {
            const {scrollTop, scrollHeight, clientHeight} = chatsRef.current;

            if (scrollHeight === 0 || clientHeight === 0) return;

            setScrollState({
                isTop: scrollTop <= 0,
                isBottom: scrollTop + clientHeight >= scrollHeight,
            });
        }
    };

    useEffect(() => {
        const currentRef = chatsRef.current;
        if (currentRef) {
            currentRef.addEventListener('scroll', handleScroll);
            handleScroll();
        }
        return () => {
            currentRef?.removeEventListener('scroll', handleScroll);
        };
    }, []);

    return (
        <Col className={s.recentlyChatsWrapper}>
            <Typography.Text strong className={s.recentlyText}>
                Недавно
            </Typography.Text>
            <Row
                className={classNames(
                    s.recentlyChats,
                    scrollState.isTop && s[`recentlyChats--onlyBottomShadow`],
                    scrollState.isBottom && s[`recentlyChats--onlyTopShadow`]
                )}
                ref={chatsRef}
            >
                <Col className={s.recentlyColumn}>
                    {chats.map((chat) => (
                        <MenuChat chat={chat} key={chat.id} />
                    ))}
                </Col>
            </Row>
        </Col>
    );
};

export default observer(MenuChats);
