import React, {FC} from 'react';

import {DeleteOutlined} from '@ant-design/icons';
import {Flex, Typography, Popover} from 'antd';
import classNames from 'classnames';
import ROUTES from 'Core/Const/Routes';
import {observer} from 'mobx-react';
import {useNavigate} from 'react-router-dom';
import {usePrompts} from 'Services/Prompts';
import useStores from 'Stores';

import s from './styles.module.scss';

interface IProps {
    chat: any;
}

const MenuChat: FC<IProps> = ({chat}: IProps) => {
    const {setCurrentHistoryChatId, setCurrentHistoryChatTitle, currentHistoryChatId} = useStores().historyStore;
    const {modifier, setIsSidebarOpen} = useStores().searchStore;
    const {getHistoryChat, deleteChatFromHistory, getHistory} = usePrompts();
    const navigate = useNavigate();

    return (
        <Flex className={s.chatMenuItemWrapper} align="center" justify="space-between">
            {!(modifier === 'Mobile' || modifier === 'LargeMobile') ? (
                <Popover content={chat.title} placement="topLeft">
                    <Typography.Text
                        className={classNames(
                            s.chatMenuItemTitle,
                            chat.id === currentHistoryChatId && s[`chatMenuItemTitle--Active`]
                        )}
                        onClick={async () => {
                            setCurrentHistoryChatId(chat.id);
                            setCurrentHistoryChatTitle(chat.title);
                            await getHistoryChat(chat.id);
                            navigate(ROUTES.APP.PATH);
                            if (modifier === 'Mobile') {
                                setIsSidebarOpen(false);
                            }
                        }}
                    >
                        {chat.title}
                    </Typography.Text>
                </Popover>
            ) : (
                <Typography.Text
                    className={classNames(
                        s.chatMenuItemTitle,
                        chat.id === currentHistoryChatId && s[`chatMenuItemTitle--Active`]
                    )}
                    onClick={async () => {
                        setCurrentHistoryChatId(chat.id);
                        setCurrentHistoryChatTitle(chat.title);
                        await getHistoryChat(chat.id);
                        navigate(ROUTES.APP.PATH);
                        if (modifier === 'Mobile') {
                            setIsSidebarOpen(false);
                        }
                    }}
                >
                    {chat.title}
                </Typography.Text>
            )}
            <DeleteOutlined
                className={s.popoverIcon}
                onClick={async () => {
                    await deleteChatFromHistory(chat.id);
                    await getHistory();
                }}
            />
        </Flex>
    );
};

export default observer(MenuChat);
