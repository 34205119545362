import {FormOutlined, RightOutlined} from '@ant-design/icons';
import {Button, Divider, Flex, Typography} from 'antd';
import classNames from 'classnames';
import {observer} from 'mobx-react';
import {useNavigate} from 'react-router-dom';
import useStores from 'Stores';

import s from './styles.module.scss';
import ROUTES from '../../../Core/Const/Routes';
import {EMessageType, ERole} from '../../../Core/Enums';
import {useSearchStore} from '../../../Services/Adapters/store';
import {usePrompts} from '../../../Services/Prompts';

const HeaderChat = () => {
    const navigate = useNavigate();
    const {setPrompt} = useStores().promptStore;
    const {setCurrentHistoryChatId, setCurrentHistoryChatTitle} = useStores().historyStore;
    const {resetMessages, setIsLoading, setIsSidebarOpen, modifier} = useStores().searchStore;
    const {currentHistoryChatTitle, currentHistoryChatId} = useStores().historyStore;
    const {setCurrentMessage, messages, addMessage} = useSearchStore();
    const {updateHistoryChat} = usePrompts();

    const hasHistory = messages.length > 0;
    const isContextEnd = messages[messages.length - 1]?.role === ERole.CONTEXT_END;
    const isResetDisabled = !hasHistory || isContextEnd;

    const handleClickReset = async (): Promise<void> => {
        setCurrentMessage(ERole.CONTEXT_END, '', '', EMessageType.TEXT);
        addMessage();
        if (currentHistoryChatId) await updateHistoryChat(currentHistoryChatId);
    };

    return (
        <>
            <Flex
                className={classNames(s.headerRow, modifier && s[`headerRow--${modifier}`])}
                align="center"
                justify="space-between"
                gap="large"
            >
                {modifier !== 'Mobile' ? (
                    <Typography.Paragraph
                        ellipsis={{
                            rows: 1,
                            expandable: false,
                        }}
                        className={s.headerTitle}
                    >
                        {currentHistoryChatTitle ?? 'Новый чат'}
                    </Typography.Paragraph>
                ) : (
                    <Flex className={s.siderContainer} gap="middle">
                        <Button
                            size="large"
                            type="default"
                            icon={<RightOutlined />}
                            shape="circle"
                            onClick={() => {
                                setIsSidebarOpen(true);
                            }}
                        />
                        <Button
                            size="large"
                            type="default"
                            icon={<FormOutlined />}
                            shape="circle"
                            className={s.newChatBtn}
                            onClick={() => {
                                setIsLoading(false);
                                resetMessages();
                                setPrompt(null);
                                setCurrentHistoryChatId(null);
                                setCurrentHistoryChatTitle(null);
                                navigate(ROUTES.APP.PATH);
                            }}
                        />
                    </Flex>
                )}
                <Flex align="center" gap="middle">
                    <Button
                        className={s.resetButton}
                        shape="round"
                        iconPosition="end"
                        onClick={handleClickReset}
                        disabled={isResetDisabled}
                    >
                        Сбросить контекст
                    </Button>
                </Flex>
            </Flex>
            <Divider className={s.divider} />
        </>
    );
};

export default observer(HeaderChat);
